import React, { useEffect, useState } from "react"
import { BsPlusLg } from "react-icons/bs"

const Addresses = ({
  customer,
  state,
  deleteCustomerAddress,
  updateCustomerAddress,
  createCustomerAddress,
  isFetching,
  countries,
  getStates,
}) => {
  const addresses = customer?.addresses || []
  const addressId = state?.addressId

  const [currentAdd, setCurrentAdd] = useState(null)
  const [countryStates, setCountryStates] = useState([])

  useEffect(() => {
    if (currentAdd?.country_code) {
      const country = countries.find(
        el => el.country_iso2 === currentAdd.country_code
      )
      if (country) {
        getStates(country.states.resource).then(states =>
          setCountryStates(states)
        )
      }
    } else {
      setCountryStates([])
    }
  }, [currentAdd?.country_code])

  function handleDelete(id) {
    let confirmMsg = null
    if (typeof window !== "undefined") {
      confirmMsg = window.confirm(
        "Are you sure you want to delete this address?"
      )
    }
    if (confirmMsg) {
      deleteCustomerAddress(id)
    }
  }

  function handleChange({ target }) {
    setCurrentAdd(prev => ({
      ...prev,
      [target.name]: target.value,
    }))
  }

  async function handleUpdateAdd(e) {
    e.preventDefault()
    const payload = { ...currentAdd, customer_id: customer.id }

    if (currentAdd.id) {
      await updateCustomerAddress([payload])
    } else {
      await createCustomerAddress([payload])
    }
    setCurrentAdd(null)
  }

  const initState = {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country_code: "US",
    first_name: "",
    last_name: "",
    phone: "",
    postal_code: "",
    state_or_province: "",
  }

  if (currentAdd) {
    return (
      <div className="w-100 m-auto" style={{ maxWidth: 700 }}>
        <form onSubmit={handleUpdateAdd}>
          <div className="form-row">
            <div className="mb-2 col-md-6">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                value={currentAdd?.first_name || ""}
                onChange={handleChange}
                required
                aria-describedby="firstNameHelp"
              />
              <small id="firstNameHelp" className="form-text text-muted">
                Required
              </small>
            </div>
            <div className="mb-2 col-md-6">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                value={currentAdd?.last_name || ""}
                onChange={handleChange}
                required
                aria-describedby="lastNameHelp"
              />
              <small id="lastNameHelp" className="form-text text-muted">
                Required
              </small>
            </div>
          </div>
          <div className="form-row">
            <div className="mb-2 col-md-6">
              <label htmlFor="company">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="company"
                name="company"
                value={currentAdd?.company || ""}
                onChange={handleChange}
              />
            </div>
            <div className="mb-2 col-md-6">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={currentAdd?.phone || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="mb-2 col-md-6">
              <label htmlFor="address1">Address Line 1</label>
              <input
                type="text"
                className="form-control"
                id="address1"
                name="address1"
                value={currentAdd?.address1 || ""}
                onChange={handleChange}
                required
                aria-describedby="address1Help"
              />
              <small id="address1Help" className="form-text text-muted">
                Required
              </small>
            </div>
            <div className="mb-2 col-md-6">
              <label htmlFor="address2">Address Line 2</label>
              <input
                type="text"
                className="form-control"
                id="address2"
                name="address2"
                value={currentAdd?.address2 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="mb-2 col-md-6">
              <label htmlFor="city">Suburb/City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                value={currentAdd?.city || ""}
                onChange={handleChange}
                required
                aria-describedby="cityHelp"
              />
              <small id="cityHelp" className="form-text text-muted">
                Required
              </small>
            </div>
            <div className="mb-2 col-md-6">
              <label htmlFor="country_code">Country</label>
              <select
                className="form-control"
                id="country_code"
                name="country_code"
                value={currentAdd?.country_code || ""}
                onChange={handleChange}
                required
                aria-describedby="countryHelp"
              >
                <option value="">Select country</option>
                {countries.map(country => (
                  <option key={country.id} value={country.country_iso2}>
                    {country.country}
                  </option>
                ))}
              </select>
              <small id="countryHelp" className="form-text text-muted">
                Required
              </small>
            </div>
          </div>
          <div className="form-row">
            <div className="mb-2 col-md-6">
              <label htmlFor="state_or_province">State/Provinve</label>
              {countryStates.length === 0 && (
                <input
                  type="text"
                  className="form-control"
                  id="state_or_province"
                  name="state_or_province"
                  value={currentAdd?.state_or_province || ""}
                  onChange={handleChange}
                  required
                  aria-describedby="stateOrProvinceHelp"
                />
              )}

              {countryStates.length > 0 && (
                <select
                  className="form-control"
                  id="state_or_province"
                  name="state_or_province"
                  value={currentAdd?.state_or_province || ""}
                  onChange={handleChange}
                  required
                  aria-describedby="stateOrProvinceHelp"
                >
                  <option value="">Select states</option>
                  {countryStates.map(countryState => (
                    <option key={countryState.id} value={countryState.state}>
                      {countryState.state}
                    </option>
                  ))}
                </select>
              )}

              <small id="stateOrProvinceHelp" className="form-text text-muted">
                Required
              </small>
            </div>
            <div className="mb-2 col-md-6">
              <label htmlFor="postal_code">Zip/Postcode</label>
              <input
                type="text"
                className="form-control"
                id="postal_code"
                name="postal_code"
                value={currentAdd?.postal_code || ""}
                onChange={handleChange}
                required
                aria-describedby="postalCodeHelp"
              />
              <small id="postalCodeHelp" className="form-text text-muted">
                Required
              </small>
            </div>
          </div>

          <div className="mt-3 text-center">
            <button
              className="btn btn-outline-dark mr-2"
              onClick={() => setCurrentAdd(null)}
            >
              Cancel
            </button>
            <button className="btn btn-dark" disabled={isFetching}>
              {isFetching
                ? currentAdd.id
                  ? "Updating.."
                  : "Saving.."
                : currentAdd.id
                ? "Update"
                : "Save"}
            </button>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className="row">
      {addresses.map(address => (
        <div key={address.id} className="col-md-4 mb-3">
          <div className="border rounded p-3">
            <h5>{[address.first_name, address.last_name].join(" ")}</h5>
            <p className="mb-0">{address.address1}</p>
            <p className="mb-0">{address.address2}</p>
            <p className="mb-0">
              {[
                address.city,
                address.state_or_province,
                address.postal_code,
              ].join(", ")}
            </p>
            <p className="mb-0">{address.country}</p>

            <div className="mt-3">
              <button
                className="btn btn-outline-dark mr-2"
                onClick={() => setCurrentAdd(address)}
              >
                Edit
              </button>
              <button
                className="btn btn-dark"
                onClick={() => handleDelete(address.id)}
                disabled={addressId === address.id}
              >
                {addressId === address.id ? "Deleting.." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="col-md-4 mb-3">
        <div
          className="border rounded p-3 h-100 d-flex align-items-center justify-content-center flex-column"
          style={{ cursor: "pointer" }}
          onClick={() => setCurrentAdd(initState)}
        >
          <BsPlusLg style={{ fontSize: 40 }} />
          <h4>New Address</h4>
        </div>
      </div>
    </div>
  )
}

export default Addresses
