import React, { useEffect } from "react"
import { Tabs, Tab } from "react-bootstrap"
import Addresses from "../components/bigcommerce/profile/Addresses"
import Orders from "../components/bigcommerce/profile/Orders"
import Settings from "../components/bigcommerce/profile/Settings"

const Profile = ({ customer_value }) => {
  // console.log("customer_value", customer_value)
  const customerErr = customer_value?.customerErr
  const customerSuccess = customer_value?.customerSuccess

  useEffect(() => {
    customer_value.getCountries()
  }, [])

  return (
    <div className="container">
      {customerErr && (
        <div className="alert alert-danger" role="alert">
          {customerErr.map((err, i) => (
            <span key={i}>{err}</span>
          ))}
        </div>
      )}

      {customerSuccess && (
        <div className="alert alert-success" role="alert">
          {customerSuccess.map((successMsg, i) => (
            <span key={i}>{successMsg}</span>
          ))}
        </div>
      )}

      <Tabs defaultActiveKey="orders" id="uncontrolled-tab-example">
        <Tab eventKey="orders" title="Orders" className="bg-white p-3">
          <Orders {...customer_value} />
        </Tab>
        <Tab eventKey="addresses" title="Addresses" className="bg-white p-3">
          <Addresses {...customer_value} />
        </Tab>
        <Tab
          eventKey="settings"
          title="Account Settings"
          className="bg-white p-3"
        >
          <Settings {...customer_value} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Profile
