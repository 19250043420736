import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import CurrencyFormatter from "../CurrencyFormatter"
import { BsArrowLeft } from "react-icons/bs"

const Orders = ({ customer }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [currentOrder, setCurrentOrder] = useState(null)
  const [currentShippingAdd, setCurrentShippingAdd] = useState([])
  const [products, setProducts] = useState([])

  useEffect(() => {
    fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v2/orders?customer_id=${customer.id}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
      }
    )
      .then(res => res.json())
      .then(async response => {
        if (response.length > 0) {
          const responseData = response
            .filter(el => el.status_id > 0)
            .sort((a, b) => {
              const c = new Date(a.date_created)
              const d = new Date(b.date_created)
              return d - c
            })

          setOrders(responseData)
        }
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (currentOrder) {
      setIsLoading(true)

      fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v2${currentOrder.shipping_addresses.resource}`,
        { credentials: "same-origin", mode: "same-origin" }
      )
        .then(res => res.json())
        .then(async response => {
          setCurrentShippingAdd(response)

          const my_products = await fetch(
            `/.netlify/functions/bigcommerce?endpoint=/v2${currentOrder.products.resource}`,
            { credentials: "same-origin", mode: "same-origin" }
          ).then(res => res.json())

          let productInfo = []
          if (my_products.length) {
            for (let j = 0; j < my_products.length; j++) {
              const product = my_products[j]

              const productImages = await fetch(
                `/.netlify/functions/bigcommerce?endpoint=/v3/catalog/products/${product.product_id}/images`,
                { credentials: "same-origin", mode: "same-origin" }
              ).then(res => res.json())

              productInfo.push({
                ...product,
                images: productImages?.data || [],
              })
            }

            setProducts(productInfo)
          }
        })
        .catch(err => console.log("[ERR]", err))
        .finally(() => setIsLoading(false))
    } else {
      setCurrentShippingAdd([])
      setProducts([])
    }
  }, [currentOrder])

  function formatDate(currentDate) {
    return new Date(currentDate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
  }

  if (isLoading) {
    return <p className="text-center">Please wait..</p>
  }

  if (currentOrder) {
    return (
      <>
        <button
          className="btn btn-link mb-3"
          onClick={() => setCurrentOrder(null)}
        >
          <BsArrowLeft /> Back
        </button>

        <div className="row">
          <div className="col-md-8">
            <h5>Order Contents</h5>
            <hr />

            {products.map(product => (
              <div className="row border-bottom pr-3" key={product.id}>
                <div className="col-md-3">
                  <img
                    className="img-fluid"
                    alt={product.id}
                    src={product.images[0].url_thumbnail}
                  />
                </div>
                <div className="col-md-7">
                  <h5>
                    {product.quantity} X {product.name}
                  </h5>

                  {product.product_options.map(option => (
                    <div key={option.id}>
                      <p className="text-muted mb-0">{`${option.display_name}: ${option.display_value}`}</p>
                    </div>
                  ))}
                </div>
                <div className="col-md-2 text-right">
                  <h5>
                    <CurrencyFormatter
                      currency={currentOrder.currency_code}
                      amount={product.base_price}
                    />
                  </h5>
                </div>
              </div>
            ))}

            {/* footer */}
            <div className="row mt-3 pr-3">
              <div className="col-md-7"></div>
              <div className="col-md-5">
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Subtotal:</h5>
                  <h5>
                    <CurrencyFormatter
                      currency={currentOrder.currency_code}
                      amount={currentOrder.subtotal_inc_tax}
                    />
                  </h5>
                </div>
                <hr />

                <div className="d-flex align-items-center justify-content-between">
                  <h5>Discount:</h5>
                  <h5>
                    <CurrencyFormatter
                      currency={currentOrder.currency_code}
                      amount={currentOrder.discount_amount * -1}
                    />
                  </h5>
                </div>
                <hr />

                <div className="d-flex align-items-center justify-content-between">
                  <h5>Shipping:</h5>
                  <h5>
                    <CurrencyFormatter
                      currency={currentOrder.currency_code}
                      amount={currentOrder.shipping_cost_inc_tax}
                    />
                  </h5>
                </div>
                <hr />

                <div className="d-flex align-items-center justify-content-between">
                  <h5>Grand Total:</h5>
                  <h5>
                    <CurrencyFormatter
                      currency={currentOrder.currency_code}
                      amount={currentOrder.total_inc_tax}
                    />
                  </h5>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h5>Order Details</h5>
            <hr />
            <p className="mb-0">{`Order status: ${currentOrder.status}`}</p>
            <p className="mb-0">{`Order date: ${formatDate(
              currentOrder.date_created
            )}`}</p>
            <p className="mb-0">
              {`Order total: `}
              <CurrencyFormatter
                currency={currentOrder.currency_code}
                amount={currentOrder.total_inc_tax}
              />
            </p>
            <p className="mb-0">{`Payment Method: ${currentOrder.payment_method}`}</p>
            <button className="btn btn-dark my-3">Print Invoice</button>

            <h5>Ship To</h5>
            <hr />
            {currentShippingAdd.map(shipping_address => (
              <div key={shipping_address.id}>
                <p className="mb-0">
                  {[
                    shipping_address.first_name,
                    shipping_address.last_name,
                  ].join(" ")}
                </p>
                <p className="mb-0">{shipping_address.street_1}</p>
                <p className="mb-0">{shipping_address.street_2}</p>
                <p className="mb-0">
                  {[
                    shipping_address.city,
                    shipping_address.state,
                    shipping_address.zip,
                  ].join(", ")}
                </p>
                <p>{shipping_address.country}</p>
              </div>
            ))}

            <h5>Bill To</h5>
            <hr />
            <p className="mb-0">
              {[
                currentOrder.billing_address.first_name,
                currentOrder.billing_address.last_name,
              ].join(" ")}
            </p>
            <p className="mb-0">{currentOrder.billing_address.street_1}</p>
            <p className="mb-0">{currentOrder.billing_address.street_2}</p>
            <p className="mb-0">
              {[
                currentOrder.billing_address.city,
                currentOrder.billing_address.state,
                currentOrder.billing_address.zip,
              ].join(", ")}
            </p>
            <p>{currentOrder.billing_address.country}</p>

            <h5>Actions</h5>
            <hr />
            <button className="btn btn-dark">Reorder</button>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="w-100 m-auto" style={{ maxWidth: 900 }}>
      {orders.map(order => (
        <div key={order.id}>
          <div className="row pt-3">
            <div className="col-md-8">
              <Link to="#" onClick={() => setCurrentOrder(order)}>
                <h5>{`Order #${order.id}`}</h5>
              </Link>
              <h6>
                <span>Total: </span>
                <CurrencyFormatter
                  currency={order.currency_code}
                  amount={order.total_inc_tax}
                />
              </h6>
              <div className="d-flex flex-wrap mt-3">
                <div className="mr-5">
                  <p className="text-uppercase mb-0 font-weight-bold">
                    Order Placed
                  </p>
                  <p>{formatDate(order.date_created)}</p>
                </div>
                <div>
                  <p className="text-uppercase mb-0 font-weight-bold">
                    Last Update
                  </p>
                  <p>{formatDate(order.date_modified)}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <h5>
                <span className="badge badge-info rounded-0">
                  {order.status}
                </span>
              </h5>
            </div>
          </div>

          <hr />
        </div>
      ))}
    </div>
  )
}

export default Orders
