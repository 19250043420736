import React, { useState } from "react"

const Settings = ({ customer, isFetching, updateCustomer }) => {
  const [fields, setFields] = useState(customer)

  function handleChange({ target }) {
    setFields(prev => ({
      ...prev,
      [target.name]: target.value,
    }))
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (fields?.password || fields?.confirmPassword) {
      if (fields?.password !== fields?.confirmPassword) {
        alert("Mismatch password.")
        return
      }

      if (!fields.currentPassword) {
        alert("Please enter current password")
        return
      }
    }

    updateCustomer(fields)
  }

  return (
    <div className="w-100 m-auto" style={{ maxWidth: 700 }}>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="mb-2 col-md-6">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              className="form-control"
              id="first_name"
              name="first_name"
              value={fields?.first_name || ""}
              onChange={handleChange}
              required
              aria-describedby="firstNameHelp"
            />
            <small id="firstNameHelp" className="form-text text-muted">
              Required
            </small>
          </div>
          <div className="mb-2 col-md-6">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="last_name"
              name="last_name"
              value={fields?.last_name || ""}
              onChange={handleChange}
              required
              aria-describedby="lastNameHelp"
            />
            <small id="lastNameHelp" className="form-text text-muted">
              Required
            </small>
          </div>
        </div>
        <div className="form-row">
          <div className="mb-2 col-md-6">
            <label htmlFor="company">Company Name</label>
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              value={fields?.company || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2 col-md-6">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              value={fields?.phone || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="mb-2 col-md-6">
            <label htmlFor="email">Email Address</label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={fields?.email || ""}
              onChange={handleChange}
              required
              aria-describedby="emailHelp"
            />
            <small id="emailHelp" className="form-text text-muted">
              Required
            </small>
          </div>
          <div className="mb-2 col-md-6">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={fields?.password || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="mb-2 col-md-6">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              value={fields?.confirmPassword || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2 col-md-6">
            <label htmlFor="currentPassword">Current Password</label>
            <input
              type="password"
              className="form-control"
              id="currentPassword"
              name="currentPassword"
              value={fields?.currentPassword || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mt-3 text-center">
          <button className="btn btn-dark" disabled={isFetching}>
            {isFetching ? "Updating.." : "Update Details"}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Settings
